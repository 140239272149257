import { useDispatch, useSelector } from 'react-redux';

import {
  handleHideAllModals,
  handleHideModal,
  handleShowModal,
  ModalsState,
} from 'reducers/modals';
import { RootState } from 'store';

import { NamesModal } from 'types/modals';

const useModal = (name: NamesModal) => {
  const { modals } = useSelector<RootState, ModalsState>(
    ({ modals }) => modals,
  );

  const dispatch = useDispatch();

  const isOpen = modals[name];

  const handleCloseModal = () => {
    dispatch(handleHideModal(name));
  };

  const handleOpenModal = () => {
    dispatch(handleShowModal(name));
  };

  const handleCloseAllModals = () => {
    dispatch(handleHideAllModals());
  };

  return {
    handleCloseModal,
    handleOpenModal,
    handleCloseAllModals,
    isOpen,
  };
};

export default useModal;
