import * as React from 'react';

import { SxProps, Theme } from '@mui/material';
import MUI_IconButton from '@mui/material/IconButton';

type IconButtonProps = React.ComponentProps<typeof MUI_IconButton>;

const IconButton: React.FC<IconButtonProps> = (props) => {
  const sx: SxProps<Theme> = {
    padding: 0,
    margin: 0,
    // eslint-disable-next-line react/prop-types
    ...(props.sx ?? {}),
  };

  return <MUI_IconButton {...props} sx={sx} />;
};

export default IconButton;
