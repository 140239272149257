import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import AuthReducer from 'reducers/auth';
import ModalReducer from 'reducers/modals';
import { points } from 'store/api';

const {
  revenueApi,
  expenseApi,
  offersApi,
  finRunUsersApi,
  statisticsApi,
  tokenApi,
  userSettingApi,
  categorymngrApi,
  statementApi,
} = points;

const store = configureStore({
  reducer: {
    modals: ModalReducer,
    auth: AuthReducer,

    [finRunUsersApi.reducerPath]: finRunUsersApi.reducer,

    [revenueApi.reducerPath]: revenueApi.reducer,

    [expenseApi.reducerPath]: expenseApi.reducer,

    [offersApi.reducerPath]: offersApi.reducer,

    [statisticsApi.reducerPath]: statisticsApi.reducer,

    [userSettingApi.reducerPath]: userSettingApi.reducer,

    [tokenApi.reducerPath]: tokenApi.reducer,

    [categorymngrApi.reducerPath]: categorymngrApi.reducer,

    [statementApi.reducerPath]: statementApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      finRunUsersApi.middleware,
      tokenApi.middleware,
      revenueApi.middleware,
      expenseApi.middleware,
      offersApi.middleware,
      statisticsApi.middleware,
      userSettingApi.middleware,
      categorymngrApi.middleware,
      statementApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

setupListeners(store.dispatch);

export default store;
