import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

import { UrlObject } from 'url';

import { ApiLocale } from 'store/api/type';

const useSafePush = () => {
  const { i18n } = useTranslation();

  const [onChanging, setOnChanging] = React.useState(false);

  const handleRouteChange = () => {
    setOnChanging(false);
  };

  const locale = i18n.language as ApiLocale;

  const router = useRouter();

  const safePush = (
    url: UrlObject,
    props?: {
      shallow?: boolean;
      locale?: string;
    },
  ) => {
    if (onChanging) return;

    setOnChanging(true);

    router.push(url, url, {
      locale,
      ...(props ?? {}),
    });
  };

  React.useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  return safePush;
};

export default useSafePush;
