import { useTranslation } from 'next-i18next';
import * as React from 'react';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { UrlObject } from 'url';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';
import useSafePush from 'hooks/useSafePush';

import Button from 'ui-kit/button';
import { ButtonProps } from 'ui-kit/button/type';

interface ButtonBackProps extends Pick<ButtonProps, 'className' | 'onClick'> {
  redirect?: UrlObject;
}

const ButtonBack: React.FC<ButtonBackProps> = ({
  className,
  onClick,
  redirect,
}) => {
  const { back } = useCustomRouter();

  const { t } = useTranslation();

  const push = useSafePush();

  const handleGoBack = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (redirect) {
      push(redirect);
    } else {
      back();
    }

    onClick?.(event);
  };

  return (
    <Button
      iconLeft={
        <ArrowRightAltIcon
          sx={{
            display: 'flex',
            color: 'currentColor',
            transform: 'rotate(180deg)',
          }}
        />
      }
      onClick={handleGoBack}
      size="S"
      variant="outlined"
      className={className}>
      {t('common:back')}
    </Button>
  );
};

export default ButtonBack;
