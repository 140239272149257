import * as React from 'react';

import cn from 'classnames';

import { TitleVariant } from './type';

import styles from './title.module.scss';

interface TitleProps {
  variant?: TitleVariant;
  className?: string;
  isNoBold?: boolean;
  isWhiteSpace?: boolean;
}

const Title: React.FC<React.PropsWithChildren<TitleProps>> = ({
  variant = 'h1',
  children,
  className = '',
  isNoBold = false,
  isWhiteSpace = true,
}) => {
  const classes = cn(styles.title, styles[`title_${variant}`], className, {
    [styles.title_normal]: isNoBold,
  });

  return (
    <p data-white-space={isWhiteSpace} className={classes}>
      {children}
    </p>
  );
};

export default Title;
