import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import cn from 'classnames';

import useChangeLanguage from 'hooks/useChangeLanguage.hook';

import { Option } from 'ui-kit/select/type';
import Title from 'ui-kit/title/title';

import { languagesNames } from 'constants/language';

import { flags } from './const';

import styles from './language-toggle.module.scss';

interface LanguageToggleProps {
  className?: string;
}

const LanguageToggle: React.FC<LanguageToggleProps> = (props) => {
  const { className } = props;

  const { i18n, t } = useTranslation();

  const changeLanguage = useChangeLanguage();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const { language } = i18n;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (locale: string) => () => {
    changeLanguage(locale, {
      successCallback: () => {
        setAnchorEl(null);
      },
    });
  };

  const languages: Option[] = [
    {
      label: t('common:language.russia'),
      value: languagesNames.RU,
    },
    {
      label: t('common:language.english'),
      value: languagesNames.EN,
    },
  ];

  const getIcon = (language: string) => {
    const flagImage = flags[language];

    return (
      <div className={styles.image}>
        <Image src={flagImage} alt={`flag-${language}`} />
      </div>
    );
  };

  const icon = getIcon(language);

  const title = languages.find(({ value }) => value === language)?.label ?? '';

  return (
    <>
      <div className={cn(styles.container, className)} onClick={handleClick}>
        <IconButton
          sx={{
            padding: 0,
          }}
          id="language-button"
          aria-controls={open ? 'language-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}>
          {icon}
        </IconButton>

        <Title variant="h5" className={styles.title}>
          {title}
        </Title>
      </div>

      <Menu
        key={language}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose(language)}
        MenuListProps={{
          'aria-labelledby': 'language-button',
        }}>
        {languages.map((language) => {
          const { label, value } = language;

          const icon = getIcon(value);

          return (
            <MenuItem key={value} onClick={handleClose(value)}>
              {icon}

              <p className={styles.text}>{label}</p>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default LanguageToggle;
