import * as React from 'react';

import { IconProps } from './type';

const Icon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.78005 12.02V9.7783M7.26008 12.02V9.7783M11.7401 12.02V9.7783M16.2201 12.02V9.7783M1.43604 14.876H17.5642V17.564H1.43604V14.876ZM1.43604 6.81195V5.01994L9.1687 1.43591L17.5642 5.01994V6.81195H1.43604Z"
        stroke="white"
        strokeWidth="1.68001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
