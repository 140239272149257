import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

import cn from 'classnames';

import Icon_1 from 'public/static/images/information/icon_1.svg';

// import Icon_2 from 'public/static/images/information/icon_2.svg';
import { useCustomRouter } from 'hooks/useCustomRouter.hook';
import useSafePush from 'hooks/useSafePush';

import Title from 'ui-kit/title';

import { Links } from 'types/links';

import styles from './information.module.scss';

interface InformationProps {
  isOpen: boolean;
}

const Information: React.FC<InformationProps> = ({ isOpen }) => {
  const { pathname } = useCustomRouter();

  const push = useSafePush();

  const { t } = useTranslation();

  const items = [
    {
      text: t('layout:information.project'),
      iconSrc: Icon_1,
      url: {
        pathname: Links.contacts,
        query: {
          redirect: pathname,
        },
      },
    },
    // {
    //   text: t('layout:information.contacts'),
    //   iconSrc: Icon_2,
    // },
  ];

  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {items.map((item) => {
          const { text, iconSrc, url } = item;

          return (
            <li
              key={text}
              className={cn(styles.item, {
                [styles.itemClose]: !isOpen,
              })}
              onClick={() => {
                push(url);
              }}>
              <div className={styles.image}>
                <Image src={iconSrc} alt="information-icon" unoptimized />
              </div>

              {isOpen ? (
                <Title variant="h6" className={styles.itemTitle}>
                  {text}
                </Title>
              ) : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Information;
