import * as React from 'react';

const Icon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <g stroke="#5985F7" strokeWidth="2" clipPath="url(#a)">
        <path
          strokeMiterlimit="10"
          d="M12.002 2c-.113 0-.168.01-.168.01a.747.747 0 0 0-.7.55c-.142.513-.337 1.018-.55 1.378-.21.354-.41.514-.542.55-.132.032-.382-.014-.742-.223-.354-.209-.773-.56-1.146-.937a.736.736 0 0 0-.878-.127 10.036 10.036 0 0 0-2.474 1.86.738.738 0 0 0-.11.873c.265.464.492.956.597 1.356.104.4.068.65 0 .764-.069.118-.269.278-.669.382-.4.105-.942.15-1.474.146a.76.76 0 0 0-.441.14.716.716 0 0 0-.268.374A10.03 10.03 0 0 0 2 12.002c0 .114.01.173.01.178a.748.748 0 0 0 .55.7c.513.141 1.005.332 1.36.541.354.21.513.405.55.533.036.127-.005.39-.214.746-.21.354-.56.764-.937 1.137a.75.75 0 0 0-.145.878 9.96 9.96 0 0 0 1.869 2.474.743.743 0 0 0 .882.114c.464-.264.96-.492 1.36-.596.4-.105.665-.068.778 0 .114.068.264.268.369.668.104.4.14.947.136 1.483a.738.738 0 0 0 .537.705c.914.278 2.897.437 2.897.437l.137-.01s.314-.054.44-.15a.717.717 0 0 0 .265-.386c.14-.514.327-1.037.541-1.392.214-.355.428-.51.555-.541.132-.032.382.005.741.214.355.21.774.564 1.147.946a.748.748 0 0 0 .878.146 10.021 10.021 0 0 0 2.474-1.88.715.715 0 0 0 .205-.427.755.755 0 0 0-.096-.464c-.268-.464-.491-.95-.596-1.355-.104-.4-.068-.664 0-.778.068-.118.273-.268.678-.373.405-.105.946-.15 1.483-.145a.733.733 0 0 0 .723-.528C21.85 13.963 22 13.005 22 12c0-.114-.01-.166-.01-.166a.747.747 0 0 0-.55-.7c-.514-.142-1.005-.332-1.36-.542-.354-.209-.523-.418-.554-.55-.037-.132.004-.391.213-.746.21-.355.56-.764.937-1.137a.736.736 0 0 0 .21-.419.733.733 0 0 0-.082-.46 9.988 9.988 0 0 0-1.86-2.464.738.738 0 0 0-.874-.11c-.464.264-.96.487-1.36.587-.4.1-.646.068-.764 0s-.278-.268-.382-.668c-.105-.4-.141-.947-.137-1.483a.715.715 0 0 0-.145-.455.727.727 0 0 0-.391-.273A10.005 10.005 0 0 0 12.066 2c-.023.005-.041 0-.064 0Z"
        />
        <circle cx="12" cy="12" r="3" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M1 1h22v22H1z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;
