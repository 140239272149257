import Image from 'next/image';
import * as React from 'react';

import cn from 'classnames';

import LogoIcon from 'public/static/images/logo.png';

import { useUserQuery } from 'store/api';

import Title from 'ui-kit/title';

import styles from './user-information.module.scss';

interface UserInformationProps {
  className?: string;
}

const UserInformation: React.FC<UserInformationProps> = ({ className }) => {
  const { data: userData } = useUserQuery();

  if (!userData) return null;

  const { first_name = '', second_name = '', last_name = '', email } = userData;

  return (
    <section
      className={cn('flex-row flex-align_center', styles.container, className)}>
      <div className={styles.image}>
        <Image width={55} height={55} src={LogoIcon} alt="" unoptimized />
      </div>

      <div className={cn('flex-col', styles.information)}>
        <Title className={styles.initials} variant="h3">
          {last_name} {first_name} {second_name}
        </Title>

        <Title className={styles.email} variant="h6">
          {email}
        </Title>
      </div>
    </section>
  );
};

export default UserInformation;
