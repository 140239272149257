import BankIcon from './bank';
import CloseIcon from './close-icon';
import DeleteIcon from './delete-icon';
import EditIcon from './edit-icon';
import InfoIcon from './info';
import NavigateExpensesIcon from './navigate/expenses';
import NavigateInvestmentsIcon from './navigate/investments';
import NavigateRevenuesIcon from './navigate/revenues';
import NavigateSettingsIcon from './navigate/settings';
import NavigateStatisticsIcon from './navigate/statistics';
import NotificationIcon from './notification-icon';
import RightArrowIcon from './right-arrow';
import SearchIcon from './search-icon';
import SettingsIcon from './settings-icon';
import StarIcon from './star-icon';
import TabArrow from './tab-arrow';

export {
  BankIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  InfoIcon,
  NavigateExpensesIcon,
  NavigateInvestmentsIcon,
  NavigateRevenuesIcon,
  NavigateSettingsIcon,
  NavigateStatisticsIcon,
  NotificationIcon,
  RightArrowIcon,
  SearchIcon,
  SettingsIcon,
  StarIcon,
  TabArrow,
};
