import { useTranslation } from 'next-i18next';

import { UrlObject } from 'url';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';
import useSafePush from 'hooks/useSafePush';

import { LocalKey, saveState } from 'utils/localStorage';

import { Callbacks } from 'types';

const useChangeLanguage = () => {
  const { query } = useCustomRouter();

  const push = useSafePush();

  const { i18n } = useTranslation();

  const changeLanguage = (locale: string, callbacks?: Callbacks) => {
    i18n
      ?.changeLanguage(locale)
      .then(() => {
        callbacks?.successCallback?.();

        const url: UrlObject = {
          query: {
            ...query,
          },
        };

        saveState(LocalKey.i18nextLng, locale);

        push(url, {
          locale,
        });
      })
      .catch(() => {
        callbacks?.errorCallback?.();
      })
      .finally(() => {
        callbacks?.finalCallback?.();
      });
  };

  return changeLanguage;
};

export default useChangeLanguage;
