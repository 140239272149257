import * as React from 'react';

import NotificationsIcon from '@mui/icons-material/Notifications';
import MUI_Badge from '@mui/material/Badge';

interface BadgeProps {
  count: number;

  className?: string;
  id?: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  color?:
    | 'default'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning';

  icon?: JSX.Element;
}

const Badge: React.FC<BadgeProps> = ({
  count,
  color = 'primary',
  icon = <NotificationsIcon color="action" />,
  ...restProps
}) => (
  <MUI_Badge badgeContent={count} color={color} {...restProps}>
    {icon}
  </MUI_Badge>
);

export default Badge;
