import { useTranslation } from 'next-i18next';
import * as React from 'react';

import { useDeleteExpenseMutation, useDeleteRevenueMutation } from 'store/api';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';

import Notification from 'ui-kit/notification';

import { namespacesNames } from 'constants/language';

import { NamesModal } from 'types/modals';

interface ModalProps {
  name: NamesModal;
}

const RevenueDeleteNotification: React.FC<ModalProps> = ({ name }) => {
  const { t } = useTranslation([namespacesNames.notification]);

  const { query } = useCustomRouter();

  const { id: revenueId = '' } = query;

  const [deleteRevenue] = useDeleteRevenueMutation();

  const handleSubmit = () => {
    deleteRevenue({
      revenueId,
    });
  };

  return (
    <Notification
      onSubmit={handleSubmit}
      title={t('notification:revenues.delete.title')}
      text={t('notification:revenues.delete.description')}
      name={name}
      buttonSuccess={{
        theme: 'error',
        text: String(t('common:delete')),
      }}
      buttonCancel={{
        theme: 'secondary',
      }}
    />
  );
};

const ExpensesDeleteNotification: React.FC<ModalProps> = ({ name }) => {
  const { t } = useTranslation([namespacesNames.notification]);

  const { query } = useCustomRouter();

  const { id: expenseId = '', batchId = '' } = query;

  const [deleteExpense] = useDeleteExpenseMutation();

  const handleSubmit = () => {
    deleteExpense({
      batchId,
      expenseId,
    });
  };

  return (
    <Notification
      onSubmit={handleSubmit}
      title={t('notification:expenses.delete.title')}
      text={t('notification:expenses.delete.description')}
      name={name}
      buttonSuccess={{
        theme: 'error',
        text: String(t('common:delete')),
      }}
      buttonCancel={{
        theme: 'secondary',
      }}
    />
  );
};

const AllModals: React.FC = () => {
  return (
    <>
      <RevenueDeleteNotification name={NamesModal.delete_revenue} />

      <ExpensesDeleteNotification name={NamesModal.delete_expenses} />
    </>
  );
};

export default AllModals;
