import * as React from 'react';

import { IconProps } from 'ui-kit/icon/type';

const Icon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m5.5 13 4.2-4.2 3 3 3.6-3.6m-12.9 12A2.4 2.4 0 0 1 1 17.8V3.4A2.4 2.4 0 0 1 3.4 1h14.4a2.4 2.4 0 0 1 2.4 2.4v14.4a2.4 2.4 0 0 1-2.4 2.4H3.4Z"
    />
  </svg>
);

export default Icon;
