import * as React from 'react';

import { motion } from 'framer-motion';

import { VariantModal } from 'ui-kit/modal/type';

import { variants } from './variants';

import styles from './backdrop.module.scss';

interface BackdropProps {
  onClick: () => void;
}

const Backdrop: React.FC<React.PropsWithChildren<BackdropProps>> = ({
  onClick,
  children,
}) => (
  <motion.div
    className={styles.backdrop}
    onClick={onClick}
    variants={variants}
    initial={VariantModal.closed}
    animate={VariantModal.open}
    exit={VariantModal.closed}>
    {children}
  </motion.div>
);

export default Backdrop;
