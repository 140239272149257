import * as React from 'react';

import { WindowSize } from 'constants/window';

import { WindowType } from 'types/window';

import useWindowDimensions from './useWindowDimensions.hook';

const useGetDeviceType = () => {
  const [type, setType] = React.useState(WindowType.desktop);

  const { width } = useWindowDimensions();

  React.useEffect(() => {
    if (width > WindowSize[WindowType.tablet]) {
      setType(WindowType.desktop);
    } else if (
      width <= WindowSize[WindowType.tablet] &&
      width > WindowSize[WindowType.mobile]
    ) {
      setType(WindowType.tablet);
    } else {
      setType(WindowType.mobile);
    }
  }, [width]);

  return type;
};

export default useGetDeviceType;
