import Image from 'next/image';
import * as React from 'react';

import Portal from '@mui/material/Portal';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import CloseIcon from 'public/static/images/close.svg';

import useModal from 'hooks/useModal.hook';

import Title from 'ui-kit/title';

import { NamesModal } from 'types/modals';

import Backdrop from './backdrop';
import { VariantModal } from './type';
import { variants } from './variants';

import styles from './modal.module.scss';

interface ModalProps {
  name: NamesModal;

  title?: string;

  onClose?: () => void;

  isShowCloseIcon?: boolean;
  isNotification?: boolean;
}

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  name,
  onClose,
  children,
  title = '',
  isShowCloseIcon = true,
  isNotification = false,
}) => {
  const { handleCloseModal, isOpen } = useModal(name);

  const handleClose = () => {
    handleCloseModal();

    if (onClose) {
      onClose();
    }
  };

  return (
    <Portal>
      <AnimatePresence>
        {isOpen && (
          <Backdrop
            onClick={() => {
              handleCloseModal();
            }}>
            <motion.div
              className={styles.wrapper}
              variants={variants}
              initial={VariantModal.closed}
              animate={VariantModal.open}
              exit={VariantModal.closed}>
              <div
                style={{
                  maxWidth: isNotification ? 600 : '100%',
                }}
                className={cn(styles.container, {
                  [styles.containerNotification]: isNotification,
                })}
                onClick={(e) => e.stopPropagation()}>
                {isShowCloseIcon && !isNotification ? (
                  <button onClick={handleClose} className={styles.icon_close}>
                    <Image
                      src={CloseIcon}
                      alt="close_icon"
                      width={20}
                      height={20}
                      unoptimized
                    />
                  </button>
                ) : null}

                {title ? (
                  <Title className={styles.title} variant="h2">
                    {title}
                  </Title>
                ) : null}

                <div
                  className={cn({
                    [styles.content]: !isNotification,
                  })}>
                  {children}
                </div>
              </div>
            </motion.div>
          </Backdrop>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default Modal;
