import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import ExitToApp from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Menu, MenuItem } from '@mui/material';
import cn from 'classnames';
import { UrlObject } from 'url';

import ArrowIcon from 'public/static/images/tab-arrow.svg';

import { setIsAuth } from 'reducers/auth';
import api from 'store/api';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';
import useGetDeviceType from 'hooks/useGetDeviceType.hook';

import Information from 'components/information';
import Logo from 'components/logo';
import UserInformation from 'components/user-information';

import {
  NavigateExpensesIcon,
  NavigateInvestmentsIcon,
  NavigateRevenuesIcon,
  NavigateSettingsIcon,
  NavigateStatisticsIcon,
} from 'ui-kit/icon';
import Title from 'ui-kit/title';

import { LocalKey, removeState } from 'utils/localStorage';

import { Nullable } from 'types';
import { Links } from 'types/links';
import { WindowType } from 'types/window';

import { DateInterval } from './type';

import styles from './navigate.module.scss';

const { finRunUsersApi } = api;

const Navigate: React.FC = () => {
  const dispatch = useDispatch();

  const { query } = useCustomRouter();

  const { pathname } = useCustomRouter();
  const [navigateElement, setNavigateElement] =
    React.useState<Nullable<HTMLElement>>(null);

  const { t } = useTranslation();

  const type = useGetDeviceType();

  const [isOpen, setIsOpen] = React.useState(true);

  const navigateItems = [
    {
      text: t('layout:title.revenues'),
      baseDateInterval: DateInterval.month,
      Icon: NavigateRevenuesIcon,
      url: Links.revenues,
    },
    {
      text: t('layout:title.expenses'),
      baseDateInterval: DateInterval.month,
      Icon: NavigateExpensesIcon,
      url: Links.expenses,
    },
    {
      text: t('layout:title.investments'),
      baseDateInterval: DateInterval.month,
      Icon: NavigateInvestmentsIcon,
      url: Links.investments,
    },
    {
      text: t('layout:title.statistics'),
      baseDateInterval: DateInterval.month,
      Icon: NavigateStatisticsIcon,
      url: Links.statistics,
    },
    {
      text: t('layout:title.settings'),
      baseDateInterval: DateInterval.month,
      Icon: NavigateSettingsIcon,
      url: Links.settings,
    },
  ];

  const handleOpenNavigate = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { currentTarget } = event;

    setNavigateElement(currentTarget);
  };

  const handleCloseNavigate = () => {
    setNavigateElement(null);
  };

  const navigateList = React.useMemo(() => {
    return (
      <div className={styles.listContainer}>
        <ul className={styles.list}>
          {navigateItems.map((item) => {
            const { text, url, Icon } = item;

            const href: UrlObject = {
              pathname: url,
              query,
            };

            const selected = url === pathname;

            return (
              <Link key={url} href={href} passHref>
                <li
                  data-selected={selected}
                  className={cn(
                    'flex-row flex-align_center pointer',
                    styles.item,
                  )}>
                  <Icon />

                  {isOpen ? (
                    <Title variant="h3" className={styles.itemTitle}>
                      {text}
                    </Title>
                  ) : null}
                </li>
              </Link>
            );
          })}
        </ul>

        {type === WindowType.desktop ? (
          <button
            className={cn(styles.itemButton, {
              [styles.itemButton__revert]: !isOpen,
            })}
            onClick={() => {
              setIsOpen((prevState) => !prevState);
            }}>
            <Image src={ArrowIcon} unoptimized alt="arrow" />
          </button>
        ) : null}
      </div>
    );
  }, [pathname, navigateItems, query, isOpen, type]);

  const isDesktop = type === WindowType.desktop;
  const isMobile = type === WindowType.mobile;

  const handleExit = () => {
    removeState(LocalKey.access_token);

    dispatch(finRunUsersApi.util.invalidateTags(['User']));

    dispatch(setIsAuth(false));
  };

  const logoHref: UrlObject = {
    pathname: Links.revenues,
    query,
  };

  return (
    <section className={styles.navigate}>
      {!isDesktop ? (
        <>
          <IconButton
            sx={{
              padding: 0,
            }}
            onClick={handleOpenNavigate}>
            <MenuIcon />
          </IconButton>

          <Menu
            anchorEl={navigateElement}
            open={Boolean(navigateElement)}
            onClose={handleCloseNavigate}>
            <UserInformation />
            <MenuItem
              onClick={handleCloseNavigate}
              sx={{
                padding: 0,
              }}>
              <div>{navigateList}</div>
            </MenuItem>
          </Menu>
        </>
      ) : null}

      <div className={styles.top}>
        <Link key={Links.revenues} href={logoHref} passHref>
          <Logo
            className={cn(styles.logo, {
              [styles.logo_close]: !isOpen,
            })}
            isOpen={isOpen}
          />
        </Link>
      </div>

      {isMobile ? (
        <>
          <IconButton
            onClick={handleExit}
            sx={{
              padding: 0,
            }}>
            <ExitToApp />
          </IconButton>
        </>
      ) : null}

      {isDesktop ? navigateList : null}

      {isDesktop ? <Information isOpen={isOpen} /> : null}
    </section>
  );
};

export default Navigate;
