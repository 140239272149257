import * as React from 'react';
import { useDispatch } from 'react-redux';

import ExitToApp from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';

import { setIsAuth } from 'reducers/auth';
import api from 'store/api';

import Notification from 'components/notification';

import { LocalKey, removeState } from 'utils/localStorage';

import styles from './tools.module.scss';

const { finRunUsersApi } = api;

const Tools: React.FC = () => {
  const dispatch = useDispatch();

  const handleExit = () => {
    removeState(LocalKey.access_token);

    dispatch(finRunUsersApi.util.invalidateTags(['User']));

    dispatch(setIsAuth(false));
  };

  return (
    <ul className={styles.list}>
      {false ? (
        <li className={styles.item}>
          <Notification />
        </li>
      ) : null}

      <li className={styles.item}>
        <IconButton
          onClick={handleExit}
          sx={{
            padding: 0,
          }}>
          <ExitToApp />
        </IconButton>
      </li>
    </ul>
  );
};

export default Tools;
