import * as React from 'react';

import Tools from 'components/tools';
import UserInformation from 'components/user-information';

import styles from './user-panel.module.scss';

const UserPanel: React.FC = () => {
  return (
    <div className={styles.container}>
      <UserInformation />
      <Tools />
    </div>
  );
};

export default UserPanel;
