import Script from 'next/script';
import * as React from 'react';

const GoogleGA: React.FC = () => {
  return (
    <>
      <Script
        id="google-ga-async"
        src={`https://www.googletagmanager.com/gtag/js?id='${process.env.NEXT_PUBLIC_GOOGLE_GA4_ID}'`}
      />

      <Script id="google-ga">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_GA4_ID}');
        `}
      </Script>
    </>
  );
};

export default GoogleGA;
