import * as React from 'react';

const Icon: React.FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.01605 12.0713C5.26012 8.65418 5.98731 6.08856 7.04669 4.40851C8.08027 2.76937 9.40085 2.00059 10.9762 2.00098C10.9833 2.00098 10.9904 2.00091 10.9975 2.00076C11.0046 2.00091 11.0117 2.00098 11.0189 2.00098C12.5942 2.00059 13.9148 2.76937 14.9484 4.40851C16.0078 6.08856 16.735 8.65418 16.979 12.0713C17.0011 12.3797 17.1645 12.6606 17.4218 12.8321C18.6959 13.6815 19.3109 14.2069 19.6306 14.647C19.8899 15.0039 19.9875 15.3476 19.9947 16H11.0186C11.0116 16 11.0045 16.0001 10.9975 16.0002C10.9905 16.0001 10.9835 16 10.9765 16H2.00039C2.00758 15.3476 2.10515 15.0039 2.36444 14.647C2.68418 14.2069 3.29921 13.6815 4.57329 12.8321C4.83056 12.6606 4.99403 12.3797 5.01605 12.0713ZM10.9975 17.9998C10.9905 17.9999 10.9835 18 10.9765 18H1.97897C0.941426 18 -0.00363449 17.1804 1.05094e-05 16.0493C0.00298353 15.1267 0.142188 14.3031 0.746386 13.4715C1.23718 12.7959 1.9982 12.1706 3.05948 11.4415C3.34882 8.07938 4.10952 5.31685 5.35494 3.34176C6.68582 1.23112 8.59419 0.000389071 10.9767 0.000976773C10.9837 0.000978493 10.9906 0.00105133 10.9975 0.00119461C11.0045 0.00105134 11.0114 0.000978493 11.0184 0.000976773C13.4009 0.000389071 15.3093 1.23112 16.6401 3.34176C17.8856 5.31685 18.6463 8.07938 18.9356 11.4415C19.9969 12.1706 20.7579 12.7959 21.2487 13.4715C21.8529 14.3031 21.9921 15.1267 21.9951 16.0493C21.9987 17.1804 21.0537 18 20.0161 18H11.0186C11.0116 18 11.0045 17.9999 10.9975 17.9998Z"
        fill="#5985F7"
      />
      <path
        d="M14.0186 18C14.0186 19.6569 12.6755 21 11.0186 21C9.36176 21 8.01862 19.6569 8.01862 18"
        stroke="#5985F7"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Icon;
