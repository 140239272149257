import * as React from 'react';

import cn from 'classnames';

import Title from 'ui-kit/title';

import { ButtonProps } from './type';

import styles from './button.module.scss';

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => {
  const {
    children,
    className,
    size = 'M',
    variant = 'contained',
    theme = 'primary',
    textVariant = 'h5',
    iconLeft,
    iconRight,
    loading,
    color,
    ...buttonProps
  } = props;

  const classes = cn(
    'flex-row flex-align_center flex-justify_center',
    styles.button,
    styles[`button_size__${size}`],
    styles[`button_variant__${variant}`],
    className,
    {
      [styles.loading]: loading,
    },
  );

  const style: React.CSSProperties = {
    [variant === 'contained' ? 'backgroundColor' : 'color']:
      buttonProps.disabled
        ? `var(--${theme}-light-color)`
        : `var(--${theme}-color)`,
    color:
      color ??
      (variant === 'outlined' || variant === 'text'
        ? `var(--primary-color)`
        : 'white'),
    boxShadow:
      variant === 'outlined' ? `0 0 0 1px var(--${theme}-color)` : undefined,
  };

  return (
    <button style={style} className={classes} {...buttonProps}>
      {iconLeft && (
        <div className={cn(styles.icon, styles.icon_left)}>{iconLeft}</div>
      )}
      {loading ? (
        <Title variant={textVariant} className={styles.text}>
          Loading...
        </Title>
      ) : (
        <Title variant={textVariant} className={styles.text}>
          {children}
        </Title>
      )}
      {iconRight && (
        <div className={cn(styles.icon, styles.icon_right)}>{iconRight}</div>
      )}
    </button>
  );
};

export default Button;
