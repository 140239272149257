import { NextRouter, useRouter } from 'next/router';

import useSafePush from 'hooks/useSafePush';

import { Links } from 'types/links';

export type CustomRouter = NextRouter & {
  query: QueryParams;
};

export enum Orders {
  asc = 'asc',
  desc = 'desc',
}

export type AlertSection =
  | SectionName.registration
  | SectionName.email_confirmation
  | SectionName.password_confirmation
  | SectionName.reset_password;

export enum QueryFields {
  startDate = 'startDate',
  endDate = 'endDate',
  datesInterval = 'datesInterval',
  email = 'email',
  section_name = 'section_name',
  alert_status = 'alert_status',
  token = 'token',
  id = 'id',
  batchId = 'batchId',
  ref = 'ref',
  redirect = 'redirect',
  category_name = 'category_name',
  sum = 'sum',
  rational = 'rational',
  name = 'name',
  pageIndex = 'pageIndex',
  pageSize = 'pageSize',
  type = 'type',
  access_token = 'access_token',
}

export interface QueryParams {
  [QueryFields.id]?: string;
  [QueryFields.batchId]?: string;

  [QueryFields.startDate]?: string;
  [QueryFields.endDate]?: string;
  [QueryFields.datesInterval]?: string;
  [QueryFields.email]?: string;
  [QueryFields.token]?: string;
  [QueryFields.ref]?: string;
  [QueryFields.name]?: string;
  [QueryFields.pageIndex]?: string;
  [QueryFields.pageSize]?: string;
  [QueryFields.sum]?: string;
  [QueryFields.rational]?: string;
  [QueryFields.type]?: string;
  [QueryFields.category_name]?: string;
  [QueryFields.access_token]?: string;

  [QueryFields.section_name]?: AlertSection;
  [QueryFields.alert_status]?: AlertStatus;
  [QueryFields.redirect]?: Links;
}

export enum AlertStatus {
  success = 'success',
  error = 'error',
  pending = 'pending',
}

export enum SectionName {
  registration = 'registration',
  email_confirmation = 'email_confirmation',
  revenue = 'revenue',
  expenses = 'expenses',
  password_confirmation = 'password_confirmation',
  reset_password = 'reset_password',
}

export const useCustomRouter = () => {
  const router: CustomRouter = useRouter();

  const push = useSafePush();

  return {
    ...router,
    push,
  };
};
