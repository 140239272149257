import { DateFormatter } from '@internationalized/date';
import { isWithinInterval } from 'date-fns';

import { Nullable } from 'types';

const formatConstructor = new DateFormatter('ru');

export const dateNow = new Date().toString();

export const getAge = (dateString: string) => {
  const today = new Date();

  const birthDate = new Date(dateString);

  let age = today.getFullYear() - birthDate.getFullYear();

  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getInputDateValue = (dateString: Nullable<string>) => {
  if (dateString === null) return '';

  const stringDate = dateString.split('.');

  if (stringDate.length < 3) {
    return '';
  }

  return `${stringDate[2]}-${stringDate[1]}-${stringDate[0]}`;
};

export const getNormalizeDate = (_date?: string) => {
  const date = _date ? new Date(_date) : new Date();

  return formatConstructor.format(date);
};

export const getIsTableDataFilter = (props: {
  interval: string[];
  value: string;
}) => {
  const { interval, value } = props;

  const [startDate, endDate] = interval;

  if (!startDate || !endDate) return true;

  const [day, month, year] = value.split('.');

  const date = new Date(`${month}.${day}.${year}`);

  const [start, end] = [new Date(+startDate), new Date(+endDate)];

  const cellValue = date.setHours(0, 0, 0, 0);

  return isWithinInterval(cellValue, {
    start,
    end,
  });
};
