import Image from 'next/image';
import * as React from 'react';

import cn from 'classnames';

import LogoIcon from 'public/static/images/logo.png';

import Title from 'ui-kit/title';

import { projectName } from 'constants/company';

import styles from './logo.module.scss';

interface LogoProps {
  className?: string;
  color?: 'white' | 'blue';
  isOpen?: boolean;
}

const Logo: React.FC<LogoProps> = ({
  className,
  color = 'blue',
  isOpen = true,
}) => {
  return (
    <div className={cn(className, styles.container)}>
      <div className={cn(className, styles.text)}>
        <div className={styles.image}>
          <Image src={LogoIcon} alt="logo" width={33} height={33} unoptimized />
        </div>

        {isOpen ? (
          <Title
            className={cn(styles.logo, {
              [styles.logo__white]: color === 'white',
              [styles.logo__blue]: color === 'blue',
            })}
            variant="logo">
            {projectName}
          </Title>
        ) : null}
      </div>
    </div>
  );
};

export default Logo;
