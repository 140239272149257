import * as React from 'react';

const Icon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" fill="none">
      <rect width="30" height="30" x=".786" fill="#FEED" rx="15" />
      <path
        stroke="#F04800"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11.162 10.376 15.787 15m0 0 4.624 4.624M15.786 15l4.624-4.624M15.786 15l-4.623 4.624"
      />
    </svg>
  );
};

export default Icon;
