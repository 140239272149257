import * as React from 'react';

import { TitleVariant } from 'ui-kit/title';

export type Theme = 'primary' | 'secondary' | 'error';

export type Size = 'S' | 'M' | 'L';

export type Variant = 'contained' | 'text' | 'outlined';

export interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: Size;
  variant?: Variant;
  theme?: Theme;
  className?: string;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  type?: 'submit';
  disabled?: boolean;
  loading?: boolean;
  color?: string;
  textVariant?: TitleVariant;
}
