import * as React from 'react';

import { IconProps } from './type';

const Icon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.49961 10.9V8.49997M8.49961 6.09998V6.16013M14.8996 8.49997C14.8996 9.41999 14.7055 10.2947 14.356 11.0853L14.9008 14.8994L11.6322 14.0822C10.7062 14.603 9.63761 14.9 8.49961 14.9C4.96499 14.9 2.09961 12.0346 2.09961 8.49997C2.09961 4.96535 4.96499 2.09998 8.49961 2.09998C12.0342 2.09998 14.8996 4.96535 14.8996 8.49997Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
