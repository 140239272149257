import { Links } from 'types/links';

export interface NavigateItem {
  text: string;
  url: Links;
  baseDateInterval: DateInterval;
}

export enum DateInterval {
  month = 'month',
  week = 'week',
}
