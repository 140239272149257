import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

import cn from 'classnames';

import BannerIcon from 'public/static/images/new.png';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';
import useGetDeviceType from 'hooks/useGetDeviceType.hook';

import LanguageToggle from 'components/language-toggle';
import Logo from 'components/logo/logo';

import Title from 'ui-kit/title/title';

import { Links } from 'types/links';
import { WindowType } from 'types/window';

import styles from './auth-layout.module.scss';

const AuthLayout: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  const { t } = useTranslation();

  const { pathname } = useCustomRouter();

  const type = useGetDeviceType();

  const isShowBanner = type !== WindowType.mobile;

  return (
    <div
      className={cn(styles.container, {
        'full-height': pathname !== Links.alert,
        [styles.grid]: isShowBanner,
      })}>
      <div className={styles.left}>
        <LanguageToggle className={styles.language} />

        <div>{children}</div>

        <div className={styles.logoContainer}>
          <Logo className={styles.logo} />
        </div>
      </div>

      {isShowBanner ? (
        <div className={styles.right}>
          <div className={styles.rightContent}>
            <div className={styles.image}>
              <Image width={250} src={BannerIcon} unoptimized alt="banner" />
            </div>

            <div className={styles.rightText}>
              <Title variant="h3">{t('layout:auth.title')}</Title>

              <Title variant="h4" isNoBold className={styles.description}>
                {t('layout:auth.description')}
              </Title>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AuthLayout;
