import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { useUnmount } from 'react-use';

import useModal from 'hooks/useModal.hook';

import Button from 'ui-kit/button';
import Modal from 'ui-kit/modal';
import Title from 'ui-kit/title';

import { NotificationProps } from './type';

import styles from './notification.module.scss';

const Notification: React.FC<NotificationProps> = ({
  name,
  onSubmit,
  text,
  title,
  description = '',
  buttonSuccess,
  buttonCancel,
}) => {
  const { t } = useTranslation();

  useUnmount(() => {
    handleClose();
  });

  const { handleCloseModal } = useModal(name);

  const handleClose = () => {
    buttonCancel?.callback?.();

    handleCloseModal();
  };

  const handleSuccess = () => {
    buttonSuccess?.callback?.();

    onSubmit();

    handleCloseModal();
  };

  return (
    <Modal name={name} onClose={handleClose} isNotification>
      <section className={styles.container}>
        <Title className={styles.title} variant="h2">
          {title}
        </Title>

        <Title className={styles.text} variant="h4">
          {text}
        </Title>

        {description && (
          <Title className={styles.description} variant="h6">
            {description}
          </Title>
        )}

        <div className={styles.buttons}>
          <Button
            onClick={handleSuccess}
            variant={buttonSuccess?.variant}
            theme={buttonSuccess?.theme}>
            {buttonSuccess?.text ?? t('common:confirm')}
          </Button>

          <Button
            onClick={handleClose}
            variant={buttonCancel?.variant}
            theme={buttonCancel?.theme}>
            {buttonCancel?.text ?? t('common:cancel')}
          </Button>
        </div>
      </section>
    </Modal>
  );
};

export default Notification;
