export enum NamesModal {
  add_revenue = 'add_revenue',
  edit_revenue = 'edit_revenue',
  delete_revenue = 'delete_revenue',

  add_expenses = 'add_expenses',
  edit_expenses = 'edit_expenses',
  delete_expenses = 'delete_expenses',

  expense_category = 'expense_category',
}
