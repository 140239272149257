import * as React from 'react';

const Icon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
      <path
        fill="#fff"
        d="M6.202 1.425c.276-.85 1.48-.85 1.757 0l.989 3.042c.123.38.478.639.878.639h3.199c.895 0 1.267 1.145.543 1.671l-2.588 1.88a.924.924 0 0 0-.335 1.033l.988 3.042c.277.851-.698 1.559-1.422 1.033l-2.587-1.88a.924.924 0 0 0-1.087 0l-2.587 1.88c-.724.526-1.698-.182-1.422-1.033l.988-3.042a.924.924 0 0 0-.335-1.033L.593 6.777c-.724-.526-.352-1.671.543-1.671h3.199c.4 0 .755-.258.878-.639l.989-3.042Z"
      />
    </svg>
  );
};

export default Icon;
