import * as React from 'react';

const Icon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" fill="none">
      <rect width="30" height="30" x=".786" fill="#FFE1C6" rx="15" />
      <path
        stroke="#F09000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m17.132 10.31 3.344 3.345M8.77 22.014l4.056-.817c.215-.044.413-.15.568-.305l9.08-9.084a1.115 1.115 0 0 0-.001-1.577L20.55 8.311a1.115 1.115 0 0 0-1.576 0l-9.08 9.086c-.155.155-.26.352-.304.567l-.819 4.051Z"
      />
    </svg>
  );
};

export default Icon;
