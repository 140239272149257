import * as React from 'react';

import { IconProps } from 'ui-kit/icon/type';

const Icon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 9.6v4.2m12-4.2v4.2M4.2 18h15.6a1.8 1.8 0 0 0 1.8-1.8V7.8A1.8 1.8 0 0 0 19.8 6H4.2a1.8 1.8 0 0 0-1.8 1.8v8.4A1.8 1.8 0 0 0 4.2 18Zm10.2-6a2.4 2.4 0 1 1-4.8 0 2.4 2.4 0 0 1 4.8 0Z"
    />
  </svg>
);

export default Icon;
