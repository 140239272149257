const languagesNames = {
  RU: 'ru',
  EN: 'en',
};

const namespacesNames = {
  common: 'common',
  error: 'error',
  passwordConfirmation: 'passwordConfirmation',
  resetPassword: 'resetPassword',
  onboarding: 'onboarding',
  contacts: 'contacts',
  registration: 'registration',
  auth: 'auth',
  expenses: 'expenses',
  investments: 'investments',
  emailConfirmation: 'emailConfirmation',
  alert: 'alert',
  revenues: 'revenues',
  statistics: 'statistics',
  layout: 'layout',
  notification: 'notification',
  modal: 'modal',
  offerta: 'offerta',
  settings: 'settings',
  statement: 'statement',
};

const namespaces = Object.values(namespacesNames);

const languages = Object.values(languagesNames);

module.exports = {
  namespaces,
  namespacesNames,
  languages,
  languagesNames,
};
