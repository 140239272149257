export const API_VERSION = 'v1';

export const keepUnusedDataFor = 86_400;

export enum PointsMain {
  statement = 'statement',
  revenue = 'revenue',
  expense = 'expense',
  offers = 'offers',
  statistics = 'statistics',
  userSettings = 'user-settings',
  categorymngr = 'categorymngr',
}

export type ApiErrorStatus = 500 | 400;

export enum Methods {
  post = 'POST',
  get = 'GET',
  delete = 'DELETE',
  put = 'PUT',
}
