import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import * as React from 'react';
import DatePicker from 'react-datepicker';

import cn from 'classnames';
import { UrlObject } from 'url';

import CalendarIcon from 'public/static/images/calendar.svg';

import { ApiLocale } from 'store/api/type';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';
import useSafePush from 'hooks/useSafePush';

import stylesTitle from 'ui-kit/title/title.module.scss';

import { dateFormat } from 'constants/date';
import { languages } from 'constants/locales';

import { getInputDateValue, getNormalizeDate } from 'utils/date';

import { Nullable } from 'types';

import styles from './calendar.module.scss';

const Calendar: React.FC = () => {
  const { query, pathname } = useCustomRouter();

  const push = useSafePush();

  const { startDate, endDate } = query;

  const [dateRange, setDateRange] = React.useState<
    [Nullable<Date>, Nullable<Date>]
  >([null, null]);

  const { i18n } = useTranslation();

  const language = i18n.language as ApiLocale;

  const locale = languages[language];

  const datePickerClassName = cn(
    styles.datePicker,
    stylesTitle.title,
    stylesTitle.title_h5,
  );

  React.useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const startDate = getNormalizeDate(dateRange[0].toISOString());
      const endDate = getNormalizeDate(dateRange[1].toISOString());

      const url: UrlObject = {
        pathname,
        query: {
          startDate,
          endDate,
        },
      };

      push(url);
    }
  }, [dateRange]);

  React.useEffect(() => {
    if (startDate && endDate) {
      const startRange = new Date(getInputDateValue(startDate));

      const endRange = new Date(getInputDateValue(endDate));

      setDateRange([startRange, endRange]);
    }

    if (!startDate && !endDate) {
      setDateRange([null, null]);
    }
  }, [startDate, endDate]);

  return (
    <section className={styles.wrapper}>
      <Image
        src={CalendarIcon}
        width={24}
        height={24}
        alt="calendar"
        unoptimized
      />

      <DatePicker
        className={datePickerClassName}
        locale={locale}
        startDate={dateRange[0]}
        endDate={dateRange[1]}
        dateFormat={dateFormat}
        disabledKeyboardNavigation
        selectsRange
        onChange={(update) => {
          setDateRange(update);
        }}
      />
    </section>
  );
};

export default Calendar;
