import * as React from 'react';

import { Menu, MenuItem } from '@mui/material';

import Badge from 'ui-kit/badge';
import { NotificationIcon } from 'ui-kit/icon';

import { Nullable } from 'types';

import styles from './notification.module.scss';

const UserValidateMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLElement>>(null);

  const open = Boolean(anchorEl);

  const handleOpenValidateMenu = (event: React.MouseEvent<HTMLSpanElement>) => {
    const { currentTarget } = event;

    setAnchorEl(currentTarget);
  };

  const handleCloseValidateMenu = () => {
    setAnchorEl(null);
  };

  const count = 0;

  return (
    <>
      <Badge
        icon={<NotificationIcon />}
        className="pointer"
        onClick={handleOpenValidateMenu}
        id="validate-menu"
        count={count}
      />
      {count > 0 ? (
        <Menu
          className={styles.menuList}
          id="validate-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseValidateMenu}>
          <MenuItem>MenuItem</MenuItem>
        </Menu>
      ) : null}
    </>
  );
};

export default UserValidateMenu;
