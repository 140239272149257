import * as React from 'react';

import { IconProps } from 'ui-kit/icon/type';

const Icon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.00002 14.9999V12.3312M9.33336 14.9999V12.3312M14.6667 14.9999V12.3312M20 14.9999V12.3312M2.40002 18.3999H21.6V21.5999H2.40002V18.3999ZM2.40002 8.7999V6.66657L11.6055 2.3999L21.6 6.66657V8.7999H2.40002Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
