import * as React from 'react';

import { IconProps } from './type';

const Icon: React.FC<IconProps> = ({ className, color }) => {
  return (
    <svg
      className={className}
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 6.49995L0 1.25L1.25 0L7.75276 6.49995L1.25 13L0 11.75L5.25 6.49995Z"
        fill={color ?? 'white'}
      />
    </svg>
  );
};

export default Icon;
