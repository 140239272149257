export enum Links {
  onboarding = '/',
  offerta = '/offerta',
  contacts = '/contacts',
  registration = '/registration',
  auth = '/authorization',
  email_confirmation = '/email_confirmation',
  alert = '/alert',
  revenues = '/revenues',
  expenses = '/expenses',
  investments = '/investments',
  statistics = '/statistics',
  password_confirmation = '/password_confirmation',
  reset_password = '/reset_password',
  settings = '/settings',
  new = '/new',
  all = '/all',
  statement = '/statement',
}
