import { enUS, ru } from 'date-fns/locale';

import { ApiLocale } from 'store/api/type';

export const languages: {
  [k in ApiLocale]: Locale;
} = {
  ru,
  en: enUS,
};
