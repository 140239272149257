import { Links } from 'types/links';

export const authPages: string[] = [
  Links.auth,
  Links.alert,
  Links.registration,
  Links.email_confirmation,
  Links.password_confirmation,
  Links.reset_password,
  Links.contacts,
  Links.offerta,
  Links.onboarding,
];

export const mainPages: string[] = [
  Links.revenues,
  Links.expenses,
  Links.investments,
  Links.statistics,
  Links.settings,
];

export const baseLinks = {
  main: Links.expenses,
  auth: Links.auth,
};
