import * as React from 'react';

import { IconProps } from './type';

const Icon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    fill="none">
    <path
      fill="currentColor"
      d="m10.022 9.132 3.973 3.973-1.414 1.414-3.978-3.977a5.5 5.5 0 1 1 1.419-1.41ZM5.5 9.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"
    />
  </svg>
);

export default Icon;
