import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'react-use';

import cn from 'classnames';
import { UrlObject } from 'url';

import { AuthState } from 'reducers/auth';
import { RootState } from 'store';
import {
  useGetUserSettingQuery,
  useGetUserSettingsLocalesQuery,
  useUserQuery,
} from 'store/api';

import useChangeLanguage from 'hooks/useChangeLanguage.hook';
import { useCustomRouter } from 'hooks/useCustomRouter.hook';
import useGetDeviceType from 'hooks/useGetDeviceType.hook';

import Calendar from 'components/calendar';
import Navigate from 'components/navigate';
import { DateInterval } from 'components/navigate/type';
import Preloader from 'components/preloader';
import UserPanel from 'components/user-panel';

import ButtonBack from 'ui-kit/button/button-back';
import Title from 'ui-kit/title';

import { authPages, baseLinks } from 'constants/links';

import { getNormalizeDate } from 'utils/date';

import { Links } from 'types/links';
import { WindowType } from 'types/window';

import AuthLayout from './auth-layout';

import styles from './layout.module.scss';

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isAuth } = useSelector<RootState, AuthState>(({ auth }) => auth);

  const { push, pathname, query } = useCustomRouter();

  const changeLanguage = useChangeLanguage();

  const { startDate = getNormalizeDate(), endDate = getNormalizeDate() } =
    query;

  const { t } = useTranslation();

  const type = useGetDeviceType();

  const { isSuccess: isSuccessGetUser } = useUserQuery();

  const { data: userSettings } = useGetUserSettingQuery();

  const { data: userSettingsLocale } = useGetUserSettingsLocalesQuery({});

  const authClasses = cn('flex-center full-width');

  const navigateItems = [
    {
      text: t('layout:title.statement'),
      baseDateInterval: DateInterval.month,
      url: Links.statement,
    },
    {
      text: t('layout:title.revenues'),
      baseDateInterval: DateInterval.month,
      url: Links.revenues,
    },
    {
      text: t('layout:title.expenses'),
      baseDateInterval: DateInterval.month,
      url: Links.expenses,
    },
    {
      text: t('layout:title.investments'),
      baseDateInterval: DateInterval.month,
      url: Links.investments,
    },
    {
      text: t('layout:title.statistics'),
      baseDateInterval: DateInterval.month,
      url: Links.statistics,
    },
    {
      text: t('layout:title.settings'),
      baseDateInterval: DateInterval.month,
      url: Links.settings,
    },
    {
      text: t('layout:title.expenses_new'),
      baseDateInterval: DateInterval.month,
      url: Links.expenses + Links.new,
    },
    {
      text: t('layout:title.revenues_new'),
      baseDateInterval: DateInterval.month,
      url: Links.revenues + Links.new,
    },
  ];

  const navigateItem = navigateItems.find((item) => item.url === pathname);

  const isAuthPage = authPages.includes(pathname);
  const isNewPage = pathname.includes(Links.new);
  const isAllPage = pathname.includes(Links.all);

  const isExpenses = pathname.includes(Links.expenses);

  const isRedirectToAuth = !isAuth && !isAuthPage;
  const isShowCalendar = pathname !== Links.settings && !isNewPage;
  const isHideContent = !isAuth && isRedirectToAuth;

  const isDesktop = type === WindowType.desktop;

  React.useEffect(() => {
    if (userSettings && userSettingsLocale) {
      const { locale_id } = userSettings;

      const findLocale = userSettingsLocale.find(({ id }) => locale_id === id);

      if (findLocale) {
        const { language_code } = findLocale;

        changeLanguage(language_code);
      }
    }
  }, [userSettingsLocale, userSettings]);

  useDebounce(
    () => {
      const isAuthPage = authPages.includes(pathname);

      if (isAuthPage) return;

      if (!isAuth) {
        const pathname = baseLinks.auth;

        const url: UrlObject = {
          pathname,
          query: {
            startDate,
            endDate,
          },
        };

        push(url);
      }
    },
    1000,
    [isAuth, pathname],
  );

  React.useEffect(() => {
    const isAuthPage = authPages.includes(pathname);

    const isContactsPage = pathname === Links.contacts;

    const isRedirectToMain = isSuccessGetUser && isAuthPage && !isContactsPage;

    if (isRedirectToMain) {
      const pathname = baseLinks.main;

      const url: UrlObject = {
        pathname,
        query: {
          startDate,
          endDate,
        },
      };

      push(url);
    }
  }, [isSuccessGetUser, pathname]);

  if (isHideContent) return <Preloader />;

  return (
    <div className={styles.wrapper}>
      <main
        className={cn('full-height', {
          [styles.main]: !isAuthPage,
          [authClasses]: isAuthPage,
        })}>
        {isAuthPage ? (
          <AuthLayout>{children}</AuthLayout>
        ) : (
          <>
            {type === WindowType.desktop ? <Navigate /> : null}

            <div className={styles.content}>
              {type !== WindowType.desktop ? <Navigate /> : null}

              {isDesktop ? <UserPanel /> : null}

              <div className={cn(styles.page)}>
                <div className={styles.contentTop}>
                  {isAllPage && isExpenses ? (
                    <ButtonBack
                      redirect={{
                        pathname: Links.expenses,
                        query,
                      }}
                      className={styles.buttonBack}
                    />
                  ) : null}

                  {navigateItem ? (
                    <Title className={styles.contentTitle} variant="h2">
                      {navigateItem.text}
                    </Title>
                  ) : null}

                  {isShowCalendar ? <Calendar /> : null}
                </div>

                {children}
              </div>
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Layout;
