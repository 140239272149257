import Image from 'next/image';
import * as React from 'react';

import cn from 'classnames';

import img from 'public/static/images/circle-preloader.svg';

import styles from './styles.module.scss';

interface PreloaderProps {
  width?: number;
  className?: string;
}

const Preloader: React.FC<PreloaderProps> = (props) => {
  const { width = 150 } = props;

  const className = cn(styles.wrapper, props.className);

  return (
    <div className={className}>
      <Image
        className={cn(styles.image)}
        width={width}
        src={img}
        alt="Loading..."
      />
    </div>
  );
};

export default Preloader;
