import { StaticImageData } from 'next/image';

import RU_FlagIcon from 'public/static/images/flags/russia.png';
import EN_FlagIcon from 'public/static/images/flags/united-kingdom.png';

export const flags: {
  [k in string]: StaticImageData;
} = {
  ru: RU_FlagIcon,
  en: EN_FlagIcon,
};
