import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { setIsAuth } from 'reducers/auth';

import { API_VERSION, keepUnusedDataFor, Methods, PointsMain } from './const';
import {
  getAuthHeader,
  getBaseQuery,
  getRequestWithParameters,
} from './helper';
import {
  CompleteResetPasswordBody,
  ExpenseAddBody,
  ExpenseAddResponse,
  ExpenseAllResponse,
  ExpenseCategoryResponse,
  ExpenseDeleteParams,
  ExpenseDeleteResponse,
  ExpenseEditRequest,
  ExpenseEditResponse,
  ExpenseItem,
  GetAllExpensesRequest,
  GetAllParams,
  GetExpenseCategoryByNameAndGroupNameRequest,
  GetExpenseCategoryByNameAndGroupNameResponse,
  GetExpenseCategoryByNameRequest,
  GetExpenseCategoryByNameResponse,
  GetExpensesPaginationRequest,
  GetExpensesPaginationResponse,
  GetGoogleTokenRequest,
  GetGoogleTokenResponse,
  GetItemParams,
  GetRevenuesPaginationRequest,
  GetRevenuesPaginationResponse,
  GetRevenuesRequest,
  GetRevenuesResponse,
  GetUserCategoriesRequest,
  GetUserCategoriesResponse,
  GetUserCurrenciesRequest,
  GetUserCurrenciesResponse,
  GetUserSettingsLocalesRequest,
  GetUserSettingsLocalesResponse,
  GetUserSettingsResponse,
  GetUserSettingsTimezonesRequest,
  GetUserSettingsTimezonesResponse,
  OffersResponse,
  RegistrationBody,
  RegistrationResponse,
  ResetPasswordBody,
  RevenueAddBody,
  RevenueAddResponse,
  RevenueAllItem,
  RevenueCategoryParams,
  RevenueCategoryResponse,
  RevenueDeleteParams,
  RevenueDeleteResponse,
  RevenueEditBody,
  RevenueEditResponse,
  StatementUploadRequest,
  StatementUploadResponse,
  StatisticsResponse,
  TokenRequest,
  TokenResponse,
  UpdateUserCategoriesRequest,
  UpdateUserCategoriesResponse,
  UpdateUserSettingsRequest,
  UserResponse,
  UserUpdateRequest,
} from './type';

const getBaseQueryWithReauth =
  (
    pointName: PointsMain,
  ): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) => {
    const { dispatch } = api;

    const result = await getBaseQuery(pointName)(args, api, extraOptions);

    const isNoAuth = result.error && result.error.status === 401;

    if (isNoAuth) {
      dispatch(setIsAuth(false));
    }

    return result;
  };

const revenueApi = createApi({
  tagTypes: ['Revenues', 'Revenue'],
  keepUnusedDataFor,
  reducerPath: 'revenueApi',
  baseQuery: getBaseQueryWithReauth(PointsMain.revenue),
  endpoints: ({ mutation, query }) => ({
    addRevenue: mutation<RevenueAddResponse, RevenueAddBody>({
      query: (body) => {
        return {
          url: 'add',
          method: Methods.post,
          headers: {
            ...getAuthHeader(),
          },
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(revenueApi.util.invalidateTags(['Revenues']));
        dispatch(offersApi.util.invalidateTags(['Offers']));
        dispatch(statisticsApi.util.invalidateTags(['Statistics']));
      },
    }),

    getRevenuesPagination: query<
      GetRevenuesPaginationResponse,
      GetRevenuesPaginationRequest
    >({
      query: (params) => {
        return {
          url: 'paging',
          params,
          headers: {
            ...getAuthHeader(),
          },
        };
      },
    }),

    getAllRevenue: query<GetRevenuesResponse, GetRevenuesRequest>({
      query: (params) => {
        return getRequestWithParameters({
          url: 'all',
          params,
        });
      },
      providesTags: (_, __, args) => [
        {
          type: 'Revenues',
          startDate: args?.startDate,
          endDate: args?.endDate,
          locale: args?.locale,
        },
      ],
    }),

    getCategoryRevenue: query<RevenueCategoryResponse, RevenueCategoryParams>({
      query: (params) => {
        return {
          url: 'categories',
          params,
          headers: {
            ...getAuthHeader(),
          },
        };
      },
    }),

    getRevenue: query<RevenueAllItem, GetItemParams>({
      query: (params) => {
        const request: FetchArgs = {
          url: 'one',
          headers: {
            ...getAuthHeader(),
          },
          params,
        };

        return request;
      },
      providesTags: (_, __, args) => [
        {
          type: 'Revenue',
          id: args?.id,
        },
      ],
    }),

    deleteRevenue: mutation<RevenueDeleteResponse, RevenueDeleteParams>({
      query: (params) => {
        return {
          url: 'delete',
          method: Methods.delete,
          headers: {
            ...getAuthHeader(),
          },
          params,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(revenueApi.util.invalidateTags(['Revenues', 'Revenue']));
        dispatch(offersApi.util.invalidateTags(['Offers']));
        dispatch(statisticsApi.util.invalidateTags(['Statistics']));
      },
    }),

    editRevenue: mutation<RevenueEditResponse, RevenueEditBody>({
      query: (body) => {
        return {
          url: 'edit',
          method: Methods.put,
          headers: {
            ...getAuthHeader(),
          },
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(revenueApi.util.invalidateTags(['Revenues', 'Revenue']));
        dispatch(statisticsApi.util.invalidateTags(['Statistics']));
        dispatch(offersApi.util.invalidateTags(['Offers']));
      },
    }),
  }),
});

const expenseApi = createApi({
  tagTypes: ['Expenses', 'Expense'],
  keepUnusedDataFor,
  reducerPath: 'expenseApiApi',
  baseQuery: getBaseQueryWithReauth(PointsMain.expense),
  endpoints: ({ mutation, query }) => ({
    addExpense: mutation<ExpenseAddResponse, ExpenseAddBody>({
      query: (body) => {
        return {
          url: 'add',
          method: Methods.post,
          headers: {
            ...getAuthHeader(),
          },
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(expenseApi.util.invalidateTags(['Expenses']));
        dispatch(offersApi.util.invalidateTags(['Offers']));
        dispatch(statisticsApi.util.invalidateTags(['Statistics']));
      },
    }),

    getAllExpenses: query<ExpenseAllResponse, GetAllExpensesRequest>({
      query: (params) => {
        return getRequestWithParameters({
          url: 'all',
          params,
        });
      },
      providesTags: (_, __, args) => [
        {
          type: 'Expenses',
          startDate: args?.startDate,
          endDate: args?.endDate,
          locale: args?.locale,
        },
      ],
    }),

    getExpensesPagination: query<
      GetExpensesPaginationResponse,
      GetExpensesPaginationRequest
    >({
      query: (params) => {
        return {
          url: 'paging',
          params,
          headers: {
            ...getAuthHeader(),
          },
        };
      },
    }),

    getExpense: query<ExpenseItem, GetItemParams>({
      query: ({ id }) => {
        const request: FetchArgs = {
          url: 'one',
          headers: {
            ...getAuthHeader(),
          },
          params: {
            batchId: id,
            id: 0,
          },
        };

        return request;
      },
      providesTags: (_, __, args) => [
        {
          type: 'Expense',
          id: args?.id,
        },
      ],
    }),

    getCategoryExpense: query<
      ExpenseCategoryResponse,
      {
        locale: string;
      }
    >({
      query: (params) => {
        return {
          url: 'categories',
          params,
          headers: {
            ...getAuthHeader(),
          },
        };
      },
    }),

    GetAllExpenseCategoryGroups: query<
      string[],
      {
        locale: string;
      }
    >({
      query: (params) => {
        return {
          url: 'categories-groups',
          params,
          headers: {
            ...getAuthHeader(),
          },
        };
      },
    }),

    deleteExpense: mutation<ExpenseDeleteResponse, ExpenseDeleteParams>({
      query: (params) => {
        return {
          url: 'delete',
          method: Methods.delete,
          headers: {
            ...getAuthHeader(),
          },
          params,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(expenseApi.util.invalidateTags(['Expenses', 'Expense']));
        dispatch(offersApi.util.invalidateTags(['Offers']));
        dispatch(statisticsApi.util.invalidateTags(['Statistics']));
      },
    }),

    editExpense: mutation<ExpenseEditResponse, ExpenseEditRequest>({
      query: (body) => {
        return {
          url: 'edit',
          method: Methods.put,
          headers: {
            ...getAuthHeader(),
          },
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(expenseApi.util.invalidateTags(['Expenses', 'Expense']));
        dispatch(offersApi.util.invalidateTags(['Offers']));
        dispatch(statisticsApi.util.invalidateTags(['Statistics']));
      },
    }),
  }),
});

const offersApi = createApi({
  tagTypes: ['Offers'],
  keepUnusedDataFor,
  reducerPath: 'offersApi',
  baseQuery: getBaseQueryWithReauth(PointsMain.offers),
  endpoints: ({ query }) => ({
    getOffers: query<OffersResponse, GetAllParams>({
      query: (params) => {
        return getRequestWithParameters({
          url: '/',
          params,
        });
      },
      providesTags: ['Offers'],
    }),
  }),
});

const statisticsApi = createApi({
  tagTypes: ['Statistics'],
  keepUnusedDataFor,
  reducerPath: 'statisticsApi',
  baseQuery: getBaseQueryWithReauth(PointsMain.statistics),
  endpoints: ({ query }) => ({
    getStatistics: query<StatisticsResponse, GetAllParams>({
      query: (params) => {
        return getRequestWithParameters({
          url: '/',
          params,
        });
      },
      providesTags: ['Statistics'],
    }),
  }),
});

export const finRunUsersApi = createApi({
  tagTypes: ['User'],
  keepUnusedDataFor,
  reducerPath: 'finRunUsersApi',
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.NEXT_PUBLIC_SECURE_API_URL +
      `${API_VERSION}` +
      '/fingram_users',
    credentials: 'include',
  }),
  endpoints: ({ query, mutation }) => ({
    registration: mutation<RegistrationResponse, RegistrationBody>({
      query: ({ ref, ...body }) => {
        const { registration_login, registration_password, email, offerta } =
          body;
        return {
          url: 'registration',
          method: Methods.post,
          body: {
            login: registration_login,
            password: registration_password,
            email,
            offerta,
          },
          params: {
            ref,
          },
        };
      },
    }),

    user: query<UserResponse, void>({
      query: () => {
        return {
          url: '/',
          headers: {
            ...getAuthHeader(),
          },
        };
      },
      providesTags: [{ type: 'User' }],
    }),

    updateUser: mutation<boolean, UserUpdateRequest>({
      query: (body) => {
        return {
          url: 'update',
          headers: {
            ...getAuthHeader(),
          },
          method: Methods.put,
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(finRunUsersApi.util.invalidateTags(['User']));
      },
    }),

    resetPassword: mutation<void, ResetPasswordBody>({
      query: ({ email }) => {
        return {
          url: `reset_password`,
          params: {
            email,
          },
          referrer: '',
          referrerPolicy: 'no-referrer',
        };
      },
    }),

    completeResetPassword: mutation<void, CompleteResetPasswordBody>({
      query: ({ pass, token }) => {
        return {
          url: `complete_reset_password`,
          params: {
            pass,
            token,
          },
          referrer: '',
          referrerPolicy: 'no-referrer',
        };
      },
    }),

    confirm_email: mutation<undefined, string>({
      query: (token) => {
        return {
          url: `confirm_email`,
          params: {
            token,
          },
          referrer: '',
          referrerPolicy: 'no-referrer',
        };
      },
    }),
  }),
});

const userSettingApi = createApi({
  tagTypes: ['Settings'],
  keepUnusedDataFor,
  reducerPath: 'userSettingApi',
  baseQuery: getBaseQueryWithReauth(PointsMain.userSettings),
  endpoints: ({ query, mutation }) => ({
    getUserSetting: query<GetUserSettingsResponse, void>({
      query: () => {
        return {
          url: '/',
          headers: {
            ...getAuthHeader(),
          },
        };
      },
      providesTags: [
        {
          type: 'Settings',
        },
      ],
    }),

    updateUserSettings: mutation<boolean, UpdateUserSettingsRequest>({
      query: (body) => {
        return {
          url: '/',
          headers: {
            ...getAuthHeader(),
          },
          method: 'PUT',
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(userSettingApi.util.invalidateTags(['Settings']));
        dispatch(expenseApi.util.invalidateTags(['Expenses']));
        dispatch(revenueApi.util.invalidateTags(['Revenues']));
        dispatch(statisticsApi.util.invalidateTags(['Statistics']));
      },
    }),

    getUserSettingsLocales: query<
      GetUserSettingsLocalesResponse,
      GetUserSettingsLocalesRequest
    >({
      query: () => {
        return {
          url: 'locales',
          headers: {
            ...getAuthHeader(),
          },
        };
      },
    }),

    getUserSettingsTimezones: query<
      GetUserSettingsTimezonesResponse,
      GetUserSettingsTimezonesRequest
    >({
      query: () => {
        return {
          url: 'timezones',
          headers: {
            ...getAuthHeader(),
          },
        };
      },
    }),

    getUserCurrencies: query<
      GetUserCurrenciesResponse,
      GetUserCurrenciesRequest
    >({
      query: (params) => {
        return {
          url: 'currencies',
          headers: {
            ...getAuthHeader(),
          },
          params,
        };
      },
    }),

    getUserCategories: query<
      GetUserCategoriesResponse,
      GetUserCategoriesRequest
    >({
      query: (params) => {
        return {
          url: 'categories',
          headers: {
            ...getAuthHeader(),
          },
          params,
        };
      },
    }),

    updateUserCategories: mutation<
      UpdateUserCategoriesResponse,
      UpdateUserCategoriesRequest
    >({
      query: (body) => {
        return {
          url: 'categories',
          headers: {
            ...getAuthHeader(),
          },
          method: Methods.post,
          body,
        };
      },
    }),
  }),
});

const tokenApi = createApi({
  tagTypes: [],
  keepUnusedDataFor,
  reducerPath: 'tokenApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_SECURE_TOKEN_URL,
    credentials: 'include',
  }),
  endpoints: ({ mutation, query }) => ({
    token: mutation<TokenResponse, TokenRequest>({
      query: ({ username, password }) => {
        const body = new URLSearchParams();

        body.append('grant_type', 'password');
        body.append('client_id', 'securityService');
        body.append('client_secret', 'securityService');
        body.append('scope', 'super');
        body.append('username', username);
        body.append('password', password);

        return {
          url: 'auth/token',
          method: Methods.post,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body,
        };
      },

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(revenueApi.util.resetApiState());
        dispatch(expenseApi.util.resetApiState());
        dispatch(offersApi.util.resetApiState());
        dispatch(statisticsApi.util.resetApiState());
        dispatch(tokenApi.util.resetApiState());
        dispatch(finRunUsersApi.util.resetApiState());

        dispatch(revenueApi.util.invalidateTags(['Revenues']));
        dispatch(expenseApi.util.invalidateTags(['Expenses']));
        dispatch(offersApi.util.invalidateTags(['Offers']));
        dispatch(statisticsApi.util.invalidateTags(['Statistics']));
      },
    }),

    getGoogleToken: query<GetGoogleTokenResponse, GetGoogleTokenRequest>({
      query: ({ params }) => {
        return {
          url: 'secure/callback/google',
          params,
        };
      },
    }),
  }),
});

const categorymngrApi = createApi({
  reducerPath: 'categorymngrApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_MAIN_API_URL,
    credentials: 'include',
  }),
  endpoints: ({ mutation }) => ({
    getExpenseCategoryByName: mutation<
      GetExpenseCategoryByNameResponse,
      GetExpenseCategoryByNameRequest
    >({
      query: (params) => {
        return {
          url: 'categorymngr/expense_category/by/product_name',
          headers: {
            ...getAuthHeader(),
          },
          method: Methods.post,
          params,
        };
      },
    }),
    getExpenseCategoryByNameAndCategoryGroup: mutation<
      GetExpenseCategoryByNameAndGroupNameResponse,
      GetExpenseCategoryByNameAndGroupNameRequest
    >({
      query: (body) => {
        return {
          url: '/v1/expense_category/by/product_name_and_category_group',
          method: Methods.post,
          headers: {
            ...getAuthHeader(),
          },
          body,
        };
      },
    }),
  }),
});

const statementApi = createApi({
  tagTypes: [],
  reducerPath: 'statementApi',
  keepUnusedDataFor,
  baseQuery: getBaseQueryWithReauth(PointsMain.statement),
  endpoints: ({ mutation }) => ({
    statementUpload: mutation<StatementUploadResponse, StatementUploadRequest>({
      query: ({ body }) => {
        return {
          url: 'upload',
          headers: {
            ...getAuthHeader(),
          },
          method: Methods.post,
          body,
        };
      },
    }),
  }),
});

export const {
  useAddRevenueMutation,
  useEditRevenueMutation,
  useDeleteRevenueMutation,
  useGetAllRevenueQuery,
  useGetRevenueQuery,
  useGetCategoryRevenueQuery,
  useGetRevenuesPaginationQuery,
} = revenueApi;

export const {
  useAddExpenseMutation,
  useGetExpenseQuery,
  useEditExpenseMutation,
  useDeleteExpenseMutation,
  useGetAllExpensesQuery,
  useGetCategoryExpenseQuery,
  useGetAllExpenseCategoryGroupsQuery,
  useGetExpensesPaginationQuery,
} = expenseApi;

export const {
  useGetUserSettingQuery,
  useUpdateUserSettingsMutation,

  useGetUserSettingsLocalesQuery,

  useGetUserSettingsTimezonesQuery,

  useGetUserCurrenciesQuery,

  useGetUserCategoriesQuery,
  useUpdateUserCategoriesMutation,
} = userSettingApi;

export const { useGetOffersQuery } = offersApi;

export const { useGetStatisticsQuery } = statisticsApi;

export const {
  useRegistrationMutation,

  useUserQuery,
  useLazyUserQuery,
  useUpdateUserMutation,

  useConfirm_emailMutation,

  useResetPasswordMutation,

  useCompleteResetPasswordMutation,
} = finRunUsersApi;

export const { useTokenMutation, useLazyGetGoogleTokenQuery } = tokenApi;

export const {
  useGetExpenseCategoryByNameMutation,
  useGetExpenseCategoryByNameAndCategoryGroupMutation,
} = categorymngrApi;

export const { useStatementUploadMutation } = statementApi;

export const points = {
  revenueApi,
  expenseApi,
  finRunUsersApi,
  offersApi,
  statisticsApi,
  tokenApi,
  userSettingApi,
  categorymngrApi,
  statementApi,
};

export default points;
