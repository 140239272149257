import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

import { loadState, LocalKey } from 'utils/localStorage';

import { API_VERSION, PointsMain } from './const';
import { ApiLocale, GetAllRequestProps } from './type';

export const getLocale = (locale: ApiLocale) => {
  const locales: {
    [k in ApiLocale]: string;
  } = {
    ru: 'ru-RU',
    en: 'en-US',
  };

  return locales[locale];
};

export const getBaseQuery = (pointName: PointsMain) =>
  fetchBaseQuery({
    baseUrl:
      process.env.NEXT_PUBLIC_MAIN_API_URL + `${API_VERSION}/${pointName}`,
  });

export const getAuthHeader = () => {
  const access_token = loadState<string>(LocalKey.access_token);

  const headers: Record<string, string> = {};

  if (access_token) {
    headers.Authorization = `Bearer ${access_token}`;
  }

  return headers;
};

export const getRequestWithParameters = (props: GetAllRequestProps) => {
  const {
    url,
    params: { startDate, endDate, ...restParams },
  } = props;

  const params =
    startDate && endDate
      ? {
          startDate,
          endDate,
        }
      : null;

  const request: FetchArgs = {
    url,
    headers: {
      ...getAuthHeader(),
    },
  };

  if (params) {
    request.params = {
      ...params,
      ...restParams,
    };
  }

  return request;
};
