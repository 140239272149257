import * as React from 'react';

import { IconProps } from 'ui-kit/icon/type';

const Icon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none">
    <path
      fill="currentColor"
      d="M1.4.993a1 1 0 0 0 0 2v-2Zm2.292 1 .966-.262a1 1 0 0 0-.966-.738v1Zm3.726 13.755-.965.261a1 1 0 0 0 1.089.731l-.124-.992Zm11.463-1.433.123.992a1 1 0 0 0 .856-.79l-.98-.202Zm1.719-8.31.98.202a1 1 0 0 0-.98-1.202v1Zm-15.821 0-.965.261.965-.261Zm-3.38-3.012h2.293v-2H1.4v2ZM7.543 16.74l11.463-1.433-.248-1.984-11.463 1.433.248 1.984Zm12.318-2.222 1.72-8.31-1.96-.406-1.719 8.31 1.959.406ZM2.727 2.254l1.087 4.012 1.93-.523-1.086-4.012-1.93.523Zm1.087 4.012 2.639 9.743 1.93-.523-2.639-9.743-1.93.523ZM20.6 5.005H4.779v2h15.82v-2ZM10 19.5a.5.5 0 0 1-.5.5v2a2.5 2.5 0 0 0 2.5-2.5h-2Zm-.5.5a.5.5 0 0 1-.5-.5H7A2.5 2.5 0 0 0 9.5 22v-2Zm-.5-.5a.5.5 0 0 1 .5-.5v-2A2.5 2.5 0 0 0 7 19.5h2Zm.5-.5a.5.5 0 0 1 .5.5h2A2.5 2.5 0 0 0 9.5 17v2Zm8.5.5a.5.5 0 0 1-.5.5v2a2.5 2.5 0 0 0 2.5-2.5h-2Zm-.5.5a.5.5 0 0 1-.5-.5h-2a2.5 2.5 0 0 0 2.5 2.5v-2Zm-.5-.5a.5.5 0 0 1 .5-.5v-2a2.5 2.5 0 0 0-2.5 2.5h2Zm.5-.5a.5.5 0 0 1 .5.5h2a2.5 2.5 0 0 0-2.5-2.5v2Z"
    />
  </svg>
);

export default Icon;
