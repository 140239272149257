import * as React from 'react';

import { IconProps } from './type';

const Icon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.17387 1.17436C1.5984 0.749824 2.2867 0.749824 2.71123 1.17436L8.34824 6.81137L13.9853 1.17436C14.4098 0.749824 15.0981 0.749824 15.5226 1.17436C15.9472 1.59889 15.9472 2.28719 15.5226 2.71172L9.88561 8.34873L15.5226 13.9857C15.9472 14.4103 15.9472 15.0986 15.5226 15.5231C15.0981 15.9476 14.4098 15.9476 13.9853 15.5231L8.34824 9.8861L2.71123 15.5231C2.2867 15.9476 1.5984 15.9476 1.17387 15.5231C0.749336 15.0986 0.749336 14.4103 1.17387 13.9857L6.81088 8.34873L1.17387 2.71172C0.749336 2.28719 0.749336 1.59889 1.17387 1.17436Z"
      fill="currentColor"
    />
  </svg>
);

export default Icon;
