import * as React from 'react';

import GoogleGA from './ga';
import GoogleMetrika from './google';
import YandexMetrika from './yandex';

const Metrics: React.FC = () => {
  return (
    <>
      <YandexMetrika />

      <GoogleMetrika />

      <GoogleGA />
    </>
  );
};

export default Metrics;
